import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Client } from "./client";

@Injectable({
    providedIn: "root"
})
export class ClientService {
    constructor(
        private http: HttpClient,
        private httpBackend: HttpBackend
    ) {}

    apiUrl = environment.REST_API_SERVER_URL;

    getClientList(data: any): Observable<any> {
       return this.http.post( this.apiUrl + 'Client/Filter', data );
    }
    getLowerLevelUsers(id:number){
        return this.http.get( this.apiUrl + 'Employee/IsLower?id='+id);
    }

    uploadClientLogo(logo: any): Observable<any> {
        const formData = new FormData();
        formData.append('Photo', logo);
       return this.http.post( this.apiUrl + 'Photo/Insert', formData);
    }

    createClient(client: Client): Observable<any> {
        return this.http.post( this.apiUrl + 'Client/Insert', client );
    }

    getClientDetails(id: number): Observable<any> {
        return this.http.get( this.apiUrl + `Client/${id}`);
    }

    getClientDetailData(clientId: number): Observable<any> {
        return this.http.get( this.apiUrl + `Client/GetDetailedClient/${clientId}` );
    }

    updateClient(id: number, client: Client): Observable<any> {
        let url = this.apiUrl + `Client/Update?id=` + id;
        return this.http.put(url, client);
    }

    deleteClient(id: number): Observable<any> {
        return this.http.delete(this.apiUrl + `Client/Delete?id=` + id);
    }

    getLocation(lat: number, lng: number) {
        const newHttpClient = new HttpClient(this.httpBackend);
        const API_KEY = environment.GOOGLE_MAPS_API_KEY;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`;
        return newHttpClient.get(url);
    }
}