import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe, SafePipe } from './pipes';

@NgModule({
  declarations: [FirstLetterPipe, SafePipe],
  imports: [CommonModule],
  exports: [FirstLetterPipe, SafePipe],
})

export class CoreModule { }
