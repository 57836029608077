import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { UserModel } from "../_models/user.model";
import { AuthModel } from "../_models/auth.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { RefreshToken, User } from "../_models/user";
import { HttpClient } from "@angular/common/http";
import jwt_decode from 'jwt-decode';
import { ResetPassword } from '../_models/reset-password';
import { ChangePassword } from '../../../pages/teams/models/change-password';
import { constants } from "src/app/shared/constants";
import * as moment from "moment";
import { MatDialog } from '@angular/material/dialog';
import { catchError, first, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AuthService implements OnDestroy {

    private unsubscribe: Subscription[] = [];
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private REST_API_SERVER = environment.REST_API_SERVER_URL;

    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public set currentUserValue(user: any) {
        this.currentUserSubject.next(user);
    }

    get isLoggedIn() {
        if (localStorage.getItem("access_token") != undefined) {
            this.loggedIn.next(true);
        }
        return this.loggedIn.asObservable();
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        private matDialog: MatDialog
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);

        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userDetails')));
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    loginUser(user: User) {
        const apiUrl = this.REST_API_SERVER + "Login/SignIn";
        return this.http.post(apiUrl, user);
    }

    isTokenExpired(): boolean {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        let userData = this.getDecodedAccessToken();
        let exp = new Date(userData?.exp * 1000);
        let now = new Date();
        if (moment(now).subtract({minutes: 5}) > moment(exp)) {
            return true;
        } else {
            return false;
        }
    }

    refreshToken(): Observable<any> {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));

        if (userDetails && userDetails?.refresh_token) {
            let user: RefreshToken = {
                grantType: "refresh_token",
                refreshToken: userDetails?.refresh_token,
                device: constants.DEVICE_WEB
            };
            return this.http.post(this.REST_API_SERVER + "LoginAuth/RefreshToken", user);
        }
    }

    getDecodedAccessToken(): any {
        try {
            return jwt_decode(localStorage.getItem('access_token'));
        }
        catch (Error) {
            return null;
        }
    }

    logoutUser() {
        this.loggedIn.next(false);
        localStorage.clear();
        this.router.navigate(['/auth/login']);
    }

    expireTokenByLogoutApi(): Observable<any> {
        return this.http.post(this.REST_API_SERVER + "LoginAuth/Logout", {});
    }

    logout() {
        this.matDialog.closeAll();
        this.loggedIn.next(false);
        localStorage.clear();
        localStorage.removeItem(this.authLocalStorageToken);
        localStorage.removeItem('fireMessageToken');
        this.router.navigate(["/auth/login"], {
            queryParams: {},
        });
    }

    getUserByToken(): Observable<UserModel> {

        return of(undefined);

        // const auth = this.getAuthFromLocalStorage();
        // if (!auth || !auth.authToken) {
        //     return of(undefined);
        // }

        // this.isLoadingSubject.next(true);
        // return this.authHttpService.getUserByToken(auth.authToken).pipe(
        //     map((user: UserModel) => {
        //         if (user) {
        //             this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        //         } else {
        //             this.logout();
        //         }
        //         return user;
        //     }),
        //     finalize(() => this.isLoadingSubject.next(false))
        // );
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post(this.REST_API_SERVER + 'Login/forgetPassword', { emailAddress: email });
    }

    resetPassword(resetPassword: ResetPassword): Observable<any> {
        return this.http.post(this.REST_API_SERVER + 'Login/resetPassword', resetPassword);
    }

    changePassword(changePassword: ChangePassword): Observable<any> {
        return this.http.post(this.REST_API_SERVER + 'LoginAuth/ChangePassword', changePassword);
    }

    public setAuthFromLocalStorage(auth: AuthModel): boolean {
        if (auth && auth.access_token) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    private handleError<T>(operation = "operation", result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    private log(message: string) {
    }

    getCaptcha(): Observable<any> {

        return this.http
          .get<any>(this.REST_API_SERVER + 'Login/GetCaptcha')
          .pipe(
            map((data) => {
              return data;
            }),
            catchError((err, caught) => {
              return err;
            })
          );
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
