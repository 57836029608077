import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubTaskListRequest } from '../models/subtask-list-request.model';
import { TaskListRequestModel } from '../models/task-list-request.model';
import * as CryptoJS from 'crypto-js';
import { TaskData } from 'src/app/shared/models/tasks/taskdata-input';
import { TaskActivity } from '../models/task-activity';
@Injectable({
  providedIn: 'root'
})
export class TaskService {

  taskDataEcryptionKey = 'taskEcrypt';

  constructor(private http: HttpClient) { }

  getEncriptedToken(taskData: TaskData) {
    this.taskDataEcryptionKey = this.taskDataEcryptionKey;
    let result = CryptoJS.AES.encrypt(JSON.stringify(taskData), this.taskDataEcryptionKey).toString();
    return result;
  }

  decryptData(encryptedString: string) {
    let bytes = CryptoJS.AES.decrypt(encryptedString, this.taskDataEcryptionKey);
    let decryptedData: TaskData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  insertTask(data): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'Task/Insert';
    return this.http.post(apiUrl, data);
  }

  taskFileUpload(isOuter: boolean, data: any, taskId?: number | string, projectId?: number | string): Observable<any> {
    var fd = new FormData();
    fd.append('File', data);
    fd.append('IsOuter', isOuter == true ? 'true' : 'false');
    fd.append('Name', data.name);
    if (taskId && taskId != null) {
      fd.append('TaskId', taskId.toString());
    }
    
    if (projectId && projectId != null) {
      fd.append('ProjectId', projectId.toString());
    }

    const apiUrl = environment.REST_API_SERVER_URL + 'TaskFile/Upload';
    return this.http.post(apiUrl, fd).pipe(
      tap(_ => this.log(`Task Upload=${data}`))
      // catchError(this.handleError<any>('Upload'))
    );
  }

  getTaskTimeline(id: number): Observable<any> {
    return this.http.get(`${environment.REST_API_SERVER_URL}TaskTimeline/GetAll?taskId=${id}`);
  }

  getTaskList(requestData: TaskListRequestModel): Observable<any> {
    return this.http.post(`${environment.REST_API_SERVER_URL}Task/GetAllBySP`, requestData);
  }

  getSubTasks(requestData: SubTaskListRequest): Observable<any> { 
    return this.http.post(`${environment.REST_API_SERVER_URL}Task/GetAllSubTaskBySP`, requestData);
  }

  taskFileIndividualDelete(fileId: number | string, taskId: number | string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        fileId: fileId,
        taskId: taskId
      }
    }
    return this.http.delete(environment.REST_API_SERVER_URL + 'TaskFile/Delete', options);
  }

  taskFileBulkDelete(fileList: any[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileList
    }
    return this.http.delete(environment.REST_API_SERVER_URL + 'TaskFile/Delete', options);
  }

  clearFiles(employeeId: number): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'TaskFile/ClearFiles', {
      employeeId: employeeId
    });
  }

  getTaskWeights() {
    const apiUrl = environment.REST_API_SERVER_URL + `TaskWeight/GetAll`;
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('Task'))
    );
  }

  taskUpdate(data: any, id): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + 'Task/Update?id=' + id;
    return this.http.put(api_url, data);
  }

  TaskStatusUpdate(id: number, model:number): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + `Task/TaskStatus?id=${id}&model=${model}`;
    return this.http.put(api_url, {});
  }

  taskProgressUpdate(id: number, progressValue: number): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + `Task/PercentageUpdate?id=${id}`;
    return this.http.put(api_url, {
        "percentageOfCompletion": progressValue
    });
  }

  TaskMemberStatusUpdate(id: number, model): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + `Task/TaskMemberStatus?id=${id}&model=${model}`;
    return this.http.put(api_url, {});
  }

  subTaskMemberStatusUpdate(id: number, status: number): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + `Task/SubTaskMemberStatus?id=${id}`;
    return this.http.put(api_url, { status: status });
  }

  getTaskById(id: number | string) {
    const apiUrl = environment.REST_API_SERVER_URL + `Task/${id}`;
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('Task'))
    );
  }

  deleteTask(id: number): Observable<any> {
    return this.http.delete(environment.REST_API_SERVER_URL + `Task/Delete?id=${id}`);
  }

  taskActivityBulkInsert(activityList: TaskActivity[]): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'TaskActivity/BulkInsert', activityList);
  }

  deleteTaskActivity(id: number): Observable<any> {
    return this.http.delete(environment.REST_API_SERVER_URL + `TaskActivity/delete?id=${id}`);
  }

  updateTaskActivityStatus(id: number, status: number): Observable<any> {
    return this.http.put(environment.REST_API_SERVER_URL + `TaskActivity/StatusUpdate?id=${id}&model=${status}`, {});
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
  }
}
