import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MeetingAvailabiltyRequest } from '../models/meeting-availability-request';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  constructor(private http: HttpClient) { }

  meetingNewFilter(filter): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'Meeting/NewFilter';
    return this.http.post(apiUrl, filter);
  }

  meetingFilter(filter): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'Meeting/Filter';
    return this.http.post(apiUrl, filter);
  }

  MeetingInsert(data: any): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'Meeting/Insert';
    return this.http.post(apiUrl, data);
  }

  getMeetingById(id: number | string) {
    const apiUrl = environment.REST_API_SERVER_URL + `Meeting/${id}`;
    return this.http.get(apiUrl);
  }

  meetingUpdate(data: any, id: number): Observable<any> {
    const api_url = environment.REST_API_SERVER_URL + 'Meeting/Update?id=' + id;
    return this.http.put(api_url, data);
  }

  MinuteOfMeetingInsert(data: any): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'MinuteOfMeeting/Insert ';
    return this.http.post(apiUrl, data);
  }

  MeetingFileUpload(data: FormData): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + 'MeetingFile/Upload';
    return this.http.post(apiUrl, data);
  }

  deleteMeetingFile(fileList: any[]): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: fileList,
    };

    return this.http
      .delete(environment.REST_API_SERVER_URL + 'MeetingFile/Delete', options);
  }

  filterClientMember(filter: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'ClientMember/Filter', filter);
  }

  changeStatus(meetingId: number, statusId: number): Observable<any> {
    return this.http.put(environment.REST_API_SERVER_URL + `Meeting/ChangeStatus?id=${meetingId}&model=${statusId}`, {});
  }

  getAllClientContacts(isVendor?: string): Observable<any> {
    if (isVendor && isVendor.length > 0) {
      return this.http.get(environment.REST_API_SERVER_URL + `Client/GetAllClientContacts?isVendor=` + isVendor);
    }
    return this.http.get(environment.REST_API_SERVER_URL + `Client/GetAllClientContacts`);
  }
  
  clearFiles(employeeId: number): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'MeetingFile/ClearFiles', {
      employeeId: employeeId
    });
  }

  getMeetingAvailability(requestData: MeetingAvailabiltyRequest): Observable<any> {
    let url = `Meeting/MeetingAvailabilty?Category=${requestData.Category}
    &EmployeeId=${requestData.EmployeeId}
    &StartDate=${requestData.StartDate}
    &EndDate=${requestData.EndDate}`;

    if (requestData.ProjectId) {
      url = `Meeting/MeetingAvailabilty?Category=${requestData.Category}
      &EmployeeId=${requestData.EmployeeId}
      &ProjectId=${requestData.ProjectId}
      &StartDate=${requestData.StartDate}
      &EndDate=${requestData.EndDate}`;
    }

    if (requestData.ClientId) {
      url = `Meeting/MeetingAvailabilty?Category=${requestData.Category}
      &EmployeeId=${requestData.EmployeeId}
      &ClientId=${requestData.ClientId}
      &StartDate=${requestData.StartDate}
      &EndDate=${requestData.EndDate}`;
    }

    return this.http.get(environment.REST_API_SERVER_URL + url);
  }

  deleteMeeting(meetingId: number): Observable<any> {
    return this.http.delete(environment.REST_API_SERVER_URL + `Meeting/Delete?id=${meetingId}`);
  }

  deleteMinutesOfMeeting(meetingMinuteId: number): Observable<any> {
    return this.http.delete(environment.REST_API_SERVER_URL + `MinuteOfMeeting/Delete?id=${meetingMinuteId}`);
  }
}
