import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../modules/auth';
import { Router } from '@angular/router';
import { LoaderService } from './components/loader/service/loader.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(
        private translate: TranslateService,
        public router: Router,
        public loaderService: LoaderService,
        private authService: AuthService,
        private toastr: ToastrService
    ) { }

    timer: NodeJS.Timeout;

    fullUrl: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!window.navigator.onLine) {
            this.toastr.error(this.translate.instant('COMMON_CONTENT.no_internet_request'), this.translate.instant('COMMON_CONTENT.no_internet'));
            return;
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => this.loaderService.show(), 1000);

        const parsedUrl = new URL(window.location.href);
        //  www.cybase.ae
        const host = parsedUrl.hostname == 'www.cybase.ae' ? 'cybase' : parsedUrl.hostname.split('.')[0];
        if (request.url === environment.signInURL || request.url === environment.forgotPasswordURL ||  request.url === environment.getCaptchaURL
            || request.url === environment.resetPasswordURL || request.url === environment.verifyEmployeeEmailURL
            || request.url === environment.sendVerificationEmailURL || request.url === environment.userManualFile) {

            /*************** Development ****************/
            // const baseUrl = 'https://api.cybaseuat.com/' + host;
            //  const baseUrl = environment.digitusuatURL + '/cybaseuae';
            let baseUrl = "";
            if (host == "localhost") {
                baseUrl = environment.digitusuatURL + '/cyberspace-entityuat'
            } else {
                baseUrl = environment.digitusuatURL + '/' + host;
            }
            // const baseUrl = environment.digitusuatURL + '/' + host;
            //   const baseUrl = environment.digitusuatURL + '/cyberspace-entityuat';
            // const baseUrl = environment.digitusuatURL + '/cyberspace-entityuat';


            //   const baseUrl = 'https://api.digitusuae.com/' + host;
            // const baseUrl = 'https://api.digitusuae.com/' + 'momsmagic-entityuae';
            if(request.url != environment.userManualFile){
                request = request.clone({
                    url: `${baseUrl + request.url}`,
                    setHeaders: {
                        Authorization: ''
                    }
                });
            }else{
                request = request.clone({
                    url: `${request.url}`,
                    setHeaders: {
                        Authorization: ''
                    }
                });
            }
        } else {
            const currentUser = JSON.parse(localStorage.getItem('userDetails'))

            /*************** Development ****************/
            const baseUrl = environment.digitusuatURL;
            // const baseUrl = 'https://api.digitusuat.com';
            // const baseUrl = 'https://api.digitusuae.com';
           
            request = request.clone({
                url: `${baseUrl + request.url}`,
                setHeaders: {
                    Authorization: `Bearer ${currentUser ? currentUser.access_token : ''}`
                }
            });
        }

        return next.handle(request).pipe(
            finalize(() => {
                this.loaderService.hide();
                if (this.timer) {
                    clearTimeout(this.timer);
                }
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        let isTokenExpired = this.authService.isTokenExpired();
                        if (isTokenExpired) {
                            this.toastr.error("Session time out, Kindly login again", "Error!");
                            this.authService.logout();
                        } else {
                            const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null;
                            if (userDetails && userDetails?.refresh_token) {
                                this.authService.refreshToken().subscribe((response: any) => {
                                    if (response.returnStatus && response.statusCode === 200) {
                                        localStorage.setItem("userDetails", JSON.stringify(response?.data));
                                        localStorage.setItem(
                                            "access_token",
                                            JSON.stringify(response.data.access_token)
                                        );
                                        this.loaderService.show();
                                        setTimeout(() => {
                                            window.location.reload();
                                            this.loaderService.hide();
                                        }, 500);
                                    }
                                }, (error) => {
                                    this.toastr.error('Session time out, Kindly login again', 'Error!');
                                    this.authService.logout();
                                });
                            }
                        }
                    }
                }
                this.loaderService.hide()
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                // const error = err.error.message || err.statusText;
                return throwError(err);
            }));
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            //   this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}